import {
  Button, Modal, Stack, Typography,
} from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { EvotruxDivider } from 'components/EvotruxDivider';
import { LoadingWrappedNode } from 'components/LoadingWrappedNode';
import { MultiSelect } from 'components/Select';
import { useShipperNetwork, useShipperShipmentPageContext, useWriterComponentState } from 'hooks';
import { useState } from 'react';

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  p: 4,
};

interface Props {
  onModalClose: VoidFunction;
  modalOpen: boolean
}

export function AddPartnersToShipment(props: Props) {
  const { shipment, onRefresh } = useShipperShipmentPageContext();
  const [selectedCarrierIds, setSelectedCarrierIds] = useState<number[]>([]);
  const {
    followingCarriers,
    loading: loadingNetwork,
    error: networkFetchError,
  } = useShipperNetwork();
  const {
    modalOpen, onModalClose,
  } = props;

  const {
    loading, write, error,
  } = useWriterComponentState({
    errorMessage: 'Failed to close project. Please contact us if the issue persists',
    writeConfig: {
      method: 'patch',
      url: shipment ? `/shipments/${shipment.info.id}/update` : '/',
      body: { private_shipment_partners: selectedCarrierIds },
    },
  });

  const onChange = (value: string[]) => {
    setSelectedCarrierIds(value.map((v) => parseInt(v, 10)));
  };

  const submit = () => {
    write(() => {
      if (onRefresh) {
        onRefresh();
      }
      setSelectedCarrierIds([]);
      onModalClose();
    });
  };

  if (shipment === undefined) {
    return <div />;
  }

  const buildOptions = () => {
    const partnersNotAlreadyInShipent = followingCarriers.filter(
      (carrier) => !shipment.carrier_acl.map(
        (acl) => acl.role.user.email,
      ).includes(carrier.user.email),
    );
    return partnersNotAlreadyInShipent.map((carrier) => ({
      label: `${carrier.user.first_name} ${carrier.user.last_name && carrier.user.last_name.length > 0 ? `${carrier.user.last_name[0]}.` : ''} - ${carrier.company?.name ? carrier.company.name : ''}`,
      value: carrier.id.toString(),
    }));
  };

  return (
    <Modal open={modalOpen} onClose={onModalClose}>
      <Stack
        sx={MODAL_STYLE}
        direction="column"
        spacing={2}
        justifyContent="center"
        alignContent="center"
      >
        <Typography variant="h6" textAlign="center">Add Partners</Typography>
        <EvotruxDivider />
        <Typography textAlign="center">
          Select which partners you would like to add to this shipment.
          {' '}
          {' '}
          Only partners without access to the shipment are listed below.
        </Typography>
        <MultiSelect
          options={buildOptions()}
          defaultValue={[]}
          inputLabel={loadingNetwork ? 'Loading Partner Network' : 'Partners'}
          value={selectedCarrierIds.map((id) => id.toString())}
          onChange={onChange}
          disabled={loading}
          width="100%"
        />
        <LoadingWrappedNode loading={loading || loadingNetwork}>
          <Stack alignItems="center" justifyContent="center" spacing={2} mt="1rem" direction="row">
            <Button variant="outlined" onClick={onModalClose}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={submit} disabled={selectedCarrierIds.length < 1}>
              Add
            </Button>
          </Stack>
        </LoadingWrappedNode>
        <ErrorTypography error={error} />
        <ErrorTypography error={networkFetchError} />
      </Stack>
    </Modal>
  );
}
