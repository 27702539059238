import { Stack } from '@mui/material';
import { ErrorTypography } from 'components/ErrorTypography';
import { MultiSelect } from 'components/Select';
import { useShipperNetworkGroups } from 'hooks';

interface Props {
  selectedGroups: string[];
  handleChange: (value: string[]) => void;
}

export function PrivateShipmentGroupSelect(props: Props) {
  const { selectedGroups, handleChange } = props;
  const {
    networkGroups,
    networkGroupsError,
  } = useShipperNetworkGroups();

  const onChange = (selectedGroupsIds: string[]) => {
    handleChange(selectedGroupsIds);
  };

  return (
    <Stack direction="column">
      <MultiSelect
        width="100%"
        inputLabel="Groups"
        defaultValue={selectedGroups || []}
        value={selectedGroups}
        onChange={onChange}
        options={networkGroups.map((group) => ({
          key: group.group.id,
          value: group.group.id.toString(),
          label: `${group.group?.name ?? ''} (${group.members.length} partners)`,
        }))}
      />
      <ErrorTypography error={networkGroupsError} />
    </Stack>
  );
}
