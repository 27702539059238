import _ from 'lodash';
import { DateTime } from 'luxon';
import { FULLY_SUPPORT_COUNTRIES, QUOTE_STATUS_PRIORITY_LIST } from '../constants';

export * from './chat';
export * from './localStorageUtils';
export * from './quotes';
export * from './shipment';
export * from './subscription';
export * from './time';

export function capitalizeFirst(words: string): string {
  return words
    .toLowerCase()
    .split(' ')
    .map((word) => (word[0] ?? '').toUpperCase() + word.substring(1))
    .join(' ');
}

export function formatText(text: string): string {
  return text.toLowerCase().split('_').join(' ');
}

export function userBelongsToCompany(user: User): boolean {
  return user?.roles.some((role) => role?.company != null);
}

export function validateEmail(email: string): string | null {
  const emailRegEx = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
  return emailRegEx.test(email) ? null : 'Invalid email format';
}

export function emailIsValid(email: string): boolean {
  if (validateEmail(email) == null) {
    return true;
  }
  return false;
}

export function getRouteOrigin(routePoints: RoutePoint[]): RoutePoint | null {
  let point = null;
  routePoints.forEach((rp) => {
    if (rp.stop_type === 'ORIGIN') {
      point = rp;
    }
  });
  return point;
}

export function getRouteDestination(routePoints: RoutePoint[]): RoutePoint | null {
  let point = null;
  routePoints.forEach((rp) => {
    if (rp.stop_type === 'DESTINATION') {
      point = rp;
    }
  });
  return point;
}

export function userHasShipperRole(user: User): boolean {
  return user.roles.some((role: Role) => role.type === 'SHIPPER');
}

export function userHasCarrierRole(user: User): boolean {
  return user.roles.some((role: Role) => role.type === 'CARRIER');
}

export function userHasAdminRoleInCompany(user: User): boolean {
  return user.roles.some((role: Role) => role.type === 'ADMINISTRATOR' && role.company != null);
}

export function userHasAnyRole(user: User): boolean {
  return user.roles.length > 0;
}

export function lbToKg(number: number): number {
  return number * 0.45359237;
}

export function naiveTimestampToISOString(naiveLocalTimestamp: string): string {
  const jsDate = new Date(naiveLocalTimestamp);
  const date = DateTime.fromJSDate(jsDate);
  return date.toISO({ suppressMilliseconds: true });
}

export function naiveDateToISOString(naiveDate: string): string {
  return new Date(naiveDate).toISOString();
}

export function startLessOrEqualToEnd(
  naiveLocalTimestampStart: string,
  naiveLocalTimestampEnd: string,
): boolean {
  // compares the two given naive local timestamps and returns wether start <= end
  const start = new Date(naiveLocalTimestampStart);
  const end = new Date(naiveLocalTimestampEnd);
  return start <= end;
}

export function ISOStringToShorthandDate(ISOTimestamp: string): string {
  return new Date(ISOTimestamp).toLocaleDateString();
}

export function ISOStringToLocalReadableTimestamp(
  ISOTimestamp: string,
  dateTimeFormatOpts?: Intl.DateTimeFormatOptions,
): string {
  // Reference: https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
  let formatOpts: Intl.DateTimeFormatOptions = {
    weekday: 'long', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', year: 'numeric',
  };
  if (dateTimeFormatOpts) {
    formatOpts = dateTimeFormatOpts;
  }
  return DateTime.fromISO(ISOTimestamp).toLocaleString(formatOpts);
}

export function unixEpochToLocalReadableTimestamp(
  unix_epoch_milliseconds: number,
  dateTimeFormatOpts?: Intl.DateTimeFormatOptions,
): string {
  // Reference: https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
  let formatOpts: Intl.DateTimeFormatOptions = {
    weekday: 'long', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', year: 'numeric',
  };
  if (dateTimeFormatOpts) {
    formatOpts = dateTimeFormatOpts;
  }
  const date = DateTime.fromMillis(unix_epoch_milliseconds);
  return date.toLocaleString(formatOpts);
}

export function ISOStringToUnixEpoch(
  ISOTimestamp: string,
): number {
  const datetime = DateTime.fromISO(ISOTimestamp);
  return datetime.toUnixInteger();
}

export function ISOStringToReadableDate(
  ISOTimestamp: string,
): string {
  // Reference: https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
  const formatOpts: Intl.DateTimeFormatOptions = {
    weekday: 'long', month: 'short', day: '2-digit', year: 'numeric',
  };
  return DateTime.fromISO(ISOTimestamp.split('T')[0]).toLocaleString(formatOpts);
}

export function ISOStringToReadableNotLocalized(
  ISOTimestamp: string,
): string {
  // keeps the input ISO string datetime in the timezone give - not
  // changed to client's local timezone
  // Referennce https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
  return DateTime.fromISO(ISOTimestamp.split('T')[0]).toFormat('LLL dd, yyyy');
}

export function countryIsFullySupported(country: string) {
  return FULLY_SUPPORT_COUNTRIES.has(country);
}

export function getReadableRoutePointLocation(routePoint: RoutePoint): string {
  // if no postal code exists then the address will be the same as the city
  if (routePoint.point_location) {
    const location = routePoint.point_location;

    if (!countryIsFullySupported(location?.country || '')) {
      return `${`${location?.city || ''}, ${location?.country || ''}${location?.postal ? `, ${location.postal}` : ''}`}`;
    }

    return `${`${location?.city || ''}, ${location?.province || ''}, ${location?.country || ''}${location?.postal ? `, ${location.postal}` : ''}`}`;
  }
  return '';
}

export const getPointDisplayText = (point: RoutePoint | CondensedShipmentPoint): string => {
  if (point?.point_location) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
    const { point_location } = point;
    return `${point_location?.city ? `${point_location.city},` : ''} ${countryIsFullySupported(point_location.country) && point_location?.province ? `${point_location.province},` : ''} ${point_location?.country ?? ''}`;
  }
  return '-';
};

export const getPointDisplayTextForPointLocation = (point: RoutePointLocation): string => `${point?.city ? `${point.city},` : ''} ${countryIsFullySupported(point.country) && point.province ? `${point.province},` : ''} ${point?.country ?? ''}`;

export function orderQuotesByQuoteStatus(condensed_quotes: CondensedQuote[] |
ShipperCondensedQuote[]): CondensedQuote[] | ShipperCondensedQuote[] {
  return condensed_quotes.sort((a, b) => QUOTE_STATUS_PRIORITY_LIST.indexOf(a.internal_state)
  - QUOTE_STATUS_PRIORITY_LIST.indexOf(b.internal_state));
}

function sortByOldest(quotes: ShipperCondensedQuote[]): ShipperCondensedQuote[] {
  quotes.sort((a, b) => ISOStringToUnixEpoch(a.created_at) - ISOStringToUnixEpoch(b.created_at));
  return quotes;
}

function sortByPrice(quotes: ShipperCondensedQuote[]): ShipperCondensedQuote[] {
  // eslint-disable-next-line
  // @ts-ignore
  quotes.sort((a, b) => a.price - b.price);
  return quotes;
}

export function shipperSortQuotes(
  quotes: ShipperCondensedQuote[],
  sortBy: QuoteSortType,
): ShipperCondensedQuote[] {
  if (sortBy === 'ALL') {
    return quotes;
  } if (sortBy === 'LATEST' || sortBy === 'OLDEST') {
    if (sortBy === 'OLDEST') {
      const sortedByOldest = sortByOldest(quotes);
      return sortedByOldest;
    }
    const sortByLatest = sortByOldest(quotes).reverse();

    return sortByLatest;
  } if (sortBy === 'HIGHEST_AMOUNT' || sortBy === 'LOWEST_AMOUNT') {
    const unsortableQuotes = quotes.filter((q) => q.price == null);
    const sortableQuotes = quotes.filter((q) => q.price != null);

    const orderedByPriceLowestFirst = sortByPrice(sortableQuotes);

    if (sortBy === 'HIGHEST_AMOUNT') {
      const orderedByPriceHighestFirst = orderedByPriceLowestFirst.reverse();
      // add unsortable quotes
      orderedByPriceHighestFirst.push(...unsortableQuotes);
    }

    // add unsortable quotes
    orderedByPriceLowestFirst.push(...unsortableQuotes);
    return orderedByPriceLowestFirst;
  }
  // filter not implemented
  return quotes;

  // first order using filter type
  // then deal with status
}

export function isShipperCondensedQuote(condensedQuote: CondensedQuote):
condensedQuote is ShipperCondensedQuote {
  return 'is_partner_quote' in condensedQuote;
}

export function getReadableIntervalPrice(
  price: SubscriptionPrice | null,
  interval: string,
): string {
  if (price) {
    const unitAmountDecimal = parseInt(price.unit_amount, 10);
    return `$${Math.round(unitAmountDecimal / 100).toFixed(2)} ${price.currency.toUpperCase()}/${interval}`;
  }
  return '-';
}

export function getReadableFixedPrice(price: TransactionPrice | null): string {
  if (price) {
    const unitAmountDecimal = parseInt(price.unit_amount, 10);
    return `$${Math.round(unitAmountDecimal / 100).toFixed(2)} ${price?.currency.toUpperCase()}`;
  }
  return '-';
}

export function getCurrencyName(currency: number | string):string {
  if (currency === 'CAD' || currency === 'USD') {
    return currency;
  }

  if (currency === 1) {
    return 'CAD';
  } if (currency === 2) {
    return 'USD';
  }
  return '?';
}

export function getPageCount(count: number, itemsPerPage: number): number {
  return Math.ceil(count / itemsPerPage);
}

export const SHIPMENTS_PAGE_SIZE = 10;

export function validateEmails(emailBlock: string): string[] {
  const emails = emailBlock.replace(/\s+/g, '').split(/[,]/);
  const validated = [];
  for (let i = 0; i < emails.length; i += 1) {
    if (validateEmail(emails[i]) !== null) {
      return [];
    }
    validated.push(emails[i]);
  }
  return validated;
}

export function excludeUserRoleInfo(roleID: number, roles: UserRoleInfo[]): UserRoleInfo[] {
  return roles.filter((r) => r.id !== roleID);
}

export function sortShipmentsByDate(
  shipments: ShipperCondensedShipment[],
): ShipperCondensedShipment[] {
  return _.sortBy(shipments, 'info.created_at').reverse();
}

export function stringHasWhiteSpace(inputEmail: string) {
  return inputEmail.indexOf(' ') >= 0;
}

export function replaceChar(charToReplace: string, newChar: string, source: string) {
  const charIndex = source.indexOf(charToReplace);
  return `${source.substring(0, charIndex)}${newChar}${source.substring(charIndex + 1)}`;
}

export function rebuildEmailAlias(email: string | null) {
  if (email == null) {
    return null;
  }

  if (stringHasWhiteSpace(email)) {
    return replaceChar(' ', '+', email);
  }
  return email;
}

export function redirectTo(url: string) {
  window.location.href = url;
}

export function renderInfo(field: undefined | string | null) {
  return field !== undefined && field != null && field.length > 0;
}

export function getEquipmentTypesDisplay(equipmentTypeNames: string[]) {
  let equipmentTypesDisplay = '';
  equipmentTypeNames?.forEach((equipmentType, index) => {
    if (index === 0) {
      equipmentTypesDisplay = `${capitalizeFirst(
        formatText(equipmentType),
      )}`;
    } else {
      equipmentTypesDisplay = `${equipmentTypesDisplay}, ${capitalizeFirst(
        formatText(equipmentType),
      )}`;
    }
  });
  return equipmentTypesDisplay;
}

export function isValidHttpUrl(url: string) {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'https:' && url.indexOf('.') !== -1;
  } catch (err) {
    return false;
  }
}

export function isInvalidNumber(number: string | number) {
  return number === '' || Number.isNaN(Number(number));
}

export function userHasNeverLoggedIn(role: UserRoleInfo) {
  return (role.user.last_login !== undefined
   && role.user.last_login == null);
}

export function filterDirectInviteCarrierACLs(carrierACLs: ShipmentACL[]): ShipmentACL[] {
  return carrierACLs.filter((acl) => acl.source === 'DIRECT_INVITE');
}
